import React from "react"
import styled from "styled-components"
import ClampLines from "react-clamp-lines"
import { Link } from "gatsby"
import { tagColors } from "../utils/tagColors"

const Container = styled(Link)`
  border: solid #eeeeee 1px;
  background: white;
  border-radius: 3px;
  transition: box-shadow 0.5s ease;
  text-decoration: none;
  color: rgba(0, 0, 0, 1);

  &:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }

  .card-photo {
    height: 200px;
    background-color: #eeeeee;
    background-image: ${props =>
      props.coverImage ? `url(${props.coverImage})` : "none"};
    background-size: cover;
    background-position: 50% 50%;
  }

  .card-content {
    padding: 1rem;

    h3 {
      font-size: 1.5rem;
      margin: 0.5rem 0;
      font-weight: 400;
    }

    p {
      font-size: 1rem;
      color: #454545;
    }
  }

  .meta {
    display: flex;
    align-items: center;
  }

  .date {
    color: rgba(0, 0, 0, 0.5);
    margin-right: 10px;
    font-size: 12px;
  }

  .tag {
    background-color: #8ca3b5;
    color: white;
    padding: 3px 12px;
    border-radius: 20px;
    font-size: 12px;
  }

  .mobile-content {
    display: none;
  }

  @media screen and (max-width: 768px) {
    .desktop-content {
      display: none;
    }
    .mobile-content {
      display: block;

      h3 {
        font-size: 1.3rem;
        word-wrap: break-all;
        hyphens: auto;
      }
    }
  }
`

export function formatPrismicDate(dateString) {
  const date = dateString.split("T")[0].split("-")
  return `${date[2]}.${date[1]}.${date[0]}`
}

const InsightCard = ({
  title,
  description,
  imageUrl,
  tags,
  date,
  uid,
  type,
  ...rest
}) => {
  let formattedTags = !tags
    ? []
    : tags.map(t => {
        const color = !tagColors[t] ? "#8CA3B5" : tagColors[t]
        const label = t
        return { color, label }
      })
  if (type === "client_result") {
    formattedTags = [
      { label: "Client Results", color: tagColors["Client Results"] },
    ]
  }
  if (type === "insight") {
    formattedTags = [{ label: "Insights", color: tagColors["Insights"] }]
  }
  const formattedDate = !date ? undefined : formatPrismicDate(date)
  const formattedLink =
    type === "client_result"
      ? `/client-result/${uid}`
      : type === "insight"
      ? `/resources/${uid}`
      : `/news/${uid}`
  return (
    <Container
      to={formattedLink}
      className="card"
      coverImage={imageUrl}
      {...rest}
    >
      <div className="card-photo" />
      <div className="card-content">
        <div className="meta">
          {formattedDate && <span className="date">{formattedDate}</span>}
          {formattedTags.map(t => (
            <span className="tag" style={{ backgroundColor: t.color }}>
              {t.label}
            </span>
          ))}
        </div>
        <div className="desktop-content">
          <ClampLines
            text={title}
            id={`title-${uid}`}
            lines={2}
            ellipsis="..."
            innerElement="h3"
            buttons={false}
          />
          <ClampLines
            text={description}
            id={`description-${uid}`}
            lines={4}
            ellipsis="..."
            innerElement="p"
            buttons={false}
          />
        </div>
        <div className="mobile-content">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </Container>
  )
}

export default InsightCard
