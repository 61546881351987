import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import ImageCard from "../components/ImageCard"
import ActionButtonLink from "../components/ActionButtonLink"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import HomePageNews from "../components/HomePageNews"
import { ContentSection } from "../components/ContentSection"
import { linkResolver } from "../utils/linkResolver"
import { RichText } from "prismic-reactjs"

const HeroImage = styled("div")`
  height: calc(100vh - 86px);
  width: 100%;
  /* background-image: url(${props => props.imageUrl});
  background-position: 25% 50%;
  background-size: cover;
  background-repeat: no-repeat; */
  resize: both;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: #454545;

  .hero-video {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: calc(100vh - 86px);
    opacity: 0.3;
  }

  @media screen and (max-width: 480px) {
    background-position: 50% 50%;
  }

  .hero-text {
    width: 100%;
    max-width: 1200px;
    flex: 0 0 auto;
    z-index: 1;
    padding: 1rem;

    h2 {
      font-size: calc(44px + (55 - 44) * ((100vw - 300px) / (1600 - 300)));
      color: white;
      margin: 0;
      margin-bottom: 3rem;

      @media screen and (max-width: 480px) {
        width: 100%;
        margin-bottom: 2rem;
      }
    }

    p {
      font-size: calc(20px + (28 - 20) * ((100vw - 300px) / (1600 - 300)));
      color: white;
      line-height: 1.5;
      margin: 0;
      margin-bottom: 3rem;

      @media screen and (max-width: 480px) {
        width: 100%;
        margin-bottom: 2rem;
      }
    }

    a {
      font-size: calc(14px + (24 - 14) * ((100vw - 300px) / (1600 - 300)));
    }
  }
`

const CardsContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    width: calc(100vw);

    .image-card {
      flex: 0 0 100%;
      margin-right: 0;
      margin-bottom: 3rem;
      border-radius: 0;
    }
  }
`

export const query = graphql`
  {
    prismic {
      allHomepages {
        edges {
          node {
            seo_page_title
            seo_page_description
            seo_h1
            about_heading
            hero_action_button_text
            hero_action_button_link1 {
              ... on PRISMIC_About {
                page_title
                _meta {
                  uid
                }
              }
            }
            hero_image
            hero_subtitle
            hero_title
            hero_video {
              ... on PRISMIC__FileLink {
                url
              }
            }
            about_paragraph
            about_page_link_text
            about_page_link {
              ... on PRISMIC_About {
                page_title
                _meta {
                  uid
                }
              }
            }
            cards {
              card_image
              card_page_link {
                ... on PRISMIC_Contact {
                  page_title
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Team {
                  page_title
                  _meta {
                    uid
                  }
                }
              }
              card_title
            }
          }
        }
      }
    }
  }
`

const renderIndexPage = data => {
  const doc = data.prismic.allHomepages.edges.slice(0, 1).pop()
  if (!doc) return null

  const seoTitle =
    (doc.node &&
      doc.node.seo_page_title &&
      doc.node.seo_page_title[0] &&
      doc.node.seo_page_title[0].text) ||
    (doc.node &&
      doc.node.page_title &&
      doc.node.page_title[0] &&
      doc.node.page_title[0].text) ||
    ""
  const seoDescription =
    (doc.node &&
      doc.node.seo_page_description &&
      doc.node.seo_page_description[0] &&
      doc.node.seo_page_description[0].text) ||
    ""

  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} />
      <HeroImage imageUrl={doc.node.hero_image.url}>
        <video autoPlay muted loop id="heroVideo" className="hero-video">
          <source src={doc.node.hero_video.url} type="video/mp4" />
        </video>
        <div className="hero-text">
          <h2>{doc.node.hero_title[0].text}</h2>
          <p>{doc.node.hero_subtitle[0].text}</p>
          <ActionButtonLink to={doc.node.hero_action_button_link1._meta.uid}>
            {doc.node.hero_action_button_text[0].text}
          </ActionButtonLink>
        </div>
      </HeroImage>
      <ContentSection>
        <div className="inner-container">
          {doc.node.seo_h1 && RichText.render(doc.node.seo_h1)}
          <HomePageNews />
        </div>
      </ContentSection>
      <ContentSection>
        <div className="inner-container">
          <h3 className="intro-text">{doc.node.about_heading[0].text}</h3>
          <div>{RichText.render(doc.node.about_paragraph, linkResolver)}</div>
          <div>
            <ActionButtonLink to={doc.node.about_page_link._meta.uid}>
              {doc.node.about_page_link_text[0].text}
            </ActionButtonLink>
          </div>
        </div>
      </ContentSection>
      <ContentSection>
        <div className="inner-container">
          <CardsContainer>
            {doc.node.cards.map((c, i) => {
              return (
                <ImageCard
                  key={i}
                  imageUrl={c.card_image.url}
                  title={c.card_title[0].text}
                  url={c.card_page_link._meta.uid}
                  alt={c.card_title[0].text}
                />
              )
            })}
          </CardsContainer>
        </div>
      </ContentSection>
    </Layout>
  )
}

export default () => {
  return (
    <>
      <StaticQuery query={query} render={withPreview(renderIndexPage, query)} />
    </>
  )
}
