import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import InsightCard from "./InsightCard"
import { RichText } from "prismic-reactjs"

export const query = graphql`
  {
    prismic {
      allHomepages {
        edges {
          node {
            news_items {
              news_item {
                ... on PRISMIC_News {
                  publication_date
                  feature_image
                  title
                  short_description
                  _meta {
                    tags
                    uid
                    lastPublicationDate
                    type
                  }
                }
                ... on PRISMIC_Client_result {
                  title
                  short_description
                  feature_image
                  _meta {
                    tags
                    uid
                    lastPublicationDate
                    type
                  }
                }
                ... on PRISMIC_Insight {
                  publication_date
                  title
                  short_description
                  feature_image
                  _meta {
                    tags
                    uid
                    lastPublicationDate
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Container = styled("div")`
  display: flex;

  .card {
    flex: 1;
    margin-right: 1rem;
  }

  .card:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 480px) {
    display: block;

    .card {
      display: block;
      margin: 0 0 1rem;
    }
  }
`

const renderHomePageNews = data => {
  const doc = data.prismic.allHomepages.edges.slice(0, 1).pop()
  if (!doc) return null

  const newsItems = doc.node.news_items.filter(item => item.news_item)
  return (
    <Container>
      {newsItems &&
        newsItems.map(item => (
          <InsightCard
            className="card"
            title={
              item.news_item.title ? RichText.asText(item.news_item.title) : ""
            }
            description={
              item.news_item.short_description
                ? RichText.asText(item.news_item.short_description)
                : ""
            }
            tags={item.news_item._meta.tags}
            date={item.news_item._meta.lastPublicationDate}
            imageUrl={
              item.news_item.feature_image
                ? item.news_item.feature_image.url
                : ""
            }
            uid={item.news_item._meta.uid}
            type={item.news_item._meta.type}
          />
        ))}
    </Container>
  )
}

export default () => {
  return (
    <>
      <StaticQuery
        query={query}
        render={withPreview(renderHomePageNews, query)}
      />
    </>
  )
}
