import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Container = styled("div")`
  overflow: hidden;
  height: 300px;
  flex: 1;
  margin-right: 2rem;
  position: relative;
  border-radius: 3px;
  cursor: pointer;

  @media screen and (max-width: 460px) {
    height: 225px;
  }

  &:last-child {
    margin-right: 0;
  }

  .feature-image {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.5);
    }

    &::before {
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: ${props =>
        props.imageUrl ? `url(${props.imageUrl})` : "none"};
      background-repeat: no-repeat;
      background-position: 50% 0;
      background-size: cover;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }

  h4 {
    color: white;
    font-weight: 700;
    font-size: 2rem;
    width: 100%;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.3);
    margin: 0;
    position: absolute;
    bottom: 0;
  }
`

const ImageCard = ({ imageUrl, title, url, alt }) => {
  return (
    <Container className="image-card" imageUrl={imageUrl}>
      <Link to={url}>
        <div className="feature-image" />
        <h4>{title}</h4>
      </Link>
    </Container>
  )
}

export default ImageCard
