import React from "react"
import { Link } from "gatsby"
import IconDoubleArrows from "../images/double-arrows.svg"
import styled from "styled-components"

const StyledLink = styled(Link)`
  display: inline-block;
  background: #0099cb;
  padding: 1rem 1.25rem;
  border-radius: 3px;
  margin: 1rem 0;
  text-decoration: none;
  color: white;
  transition: box-shadow 0.2s ease, padding 0.5s ease;
  font-family: "Montserrat", "Open Sans", "Helvetica", "Arial", sans-serif;
  display: inline-block;
  cursor: pointer;
  z-index: 1;

  .text {
    flex: 0 0 auto;
    margin-right: 2rem;
  }

  .arrows {
    flex: 0 0 auto;
    transform: translateY(1px);
  }

  /* &:hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  } */
`

const ActionButtonLink = ({ to, children, ...rest }) => {
  return (
    <StyledLink to={to} {...rest}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span className="text">{children}</span>
        <IconDoubleArrows className="arrows" />
      </div>
    </StyledLink>
  )
}

export default ActionButtonLink
